<!-- 外汇环境配置的首页 -->
<template>
  <div class="over-bg black-home">
    <div class="banner pd-tp-60 flex flex-column js-ct share-color parent">
      <div class="text-left mg-lf-banner">
        <div class="color-04 text-32 mg-bt-xs text-bolder">
          {{ $t("newHomePage.headBannerTit", { appName: state.appName }) }}
        </div>
        <div class="color-04 text-20 w-50 text-bold lh-104">
          {{ $t("newHomePage.headBannerTip1") }}
        </div>
        <div class="color-04 text-20 w-50 lh-104">
          {{ $t("newHomePage.headBannerTip2") }}
        </div>
        <div class="mg-tp-40 text-14 color-04 flex align-center text-center">
          <div
            v-if="!isLogin"
            @click="toPath('/register')"
            class="radius-10 white-bg reg-login-btn point"
          >
            {{ $t("register.register") }}
          </div>
          <div
            v-if="!isLogin"
            @click="toPath('/login')"
            class="text-white blue-bg radius-10 mg-lf-sm reg-login-btn point"
          >
            {{ $t("login.login") }}
          </div>
        </div>
      </div>
    </div>
    <!-- 抢占交易优势 -->
    <div class="flex js-ct ">
      <div class="w-1120">
        <div class="title mt-120">
          <div class="mg-bt-md">{{ $t("newHomePage.seizeTrad") }}</div>
          <div class="text-14 text-normal">
            {{ $t("newHomePage.seizeTradTip") }}
          </div>
        </div>
        <div class="flex js-sp  mg-bt-xl">
          <div class="item text-left">
            <div class="mg-bt-xs flex js-ed">
              <svg-icon
                data-_icon-name="ic_new_home_icon1"
                class="text-80 text-share"
              ></svg-icon>
            </div>
            <div class="text-18 text-bold color-04 mg-bt-xs">
              {{ $t("newHomePage.bestSpreads") }}
            </div>
            <div class="text-888 text-14">
              {{ $t("newHomePage.bestSpreadsTip") }}
            </div>
          </div>
          <div class="item text-left">
            <div class="mg-bt-xs flex js-ed">
              <svg-icon
                data-_icon-name="ic_new_home_icon2"
                class="text-80 text-share"
              ></svg-icon>
            </div>
            <div class="text-18 text-bold color-04 mg-bt-xs">
              {{ $t("newHomePage.liquidationProtection") }}
            </div>
            <div class="text-888 text-14">
              {{ $t("newHomePage.liquidationProtectionTip") }}
            </div>
          </div>
          <div class="item text-left">
            <div class="mg-bt-xs flex js-ed">
              <svg-icon
                data-_icon-name="ic_new_home_icon3"
                class="text-80 text-share"
              ></svg-icon>
            </div>
            <div class="text-18 text-bold color-04 mg-bt-xs">
              {{ $t("newHomePage.noOvernightFee") }}
            </div>
            <div class="text-888 text-14">
              {{ $t("newHomePage.noOvernightFeeTip") }}
            </div>
          </div>
        </div>
        <div class="flex js-sp ">
          <div class="item text-left">
            <div class="mg-bt-xs flex js-ed">
              <svg-icon
                data-_icon-name="ic_new_home_icon4"
                class="text-80 text-share"
              ></svg-icon>
            </div>
            <div class="text-18 text-bold color-04 mg-bt-xs">
              {{ $t("newHomePage.fastExecution") }}
            </div>
            <div class="text-888 text-14">
              {{ $t("newHomePage.fastExecutionTip") }}
            </div>
          </div>
          <div class="item text-left">
            <div class="mg-bt-xs flex js-ed">
              <svg-icon
                data-_icon-name="ic_new_home_icon5"
                class="text-80 text-share"
              ></svg-icon>
            </div>
            <div class="text-18 text-bold color-04 mg-bt-xs">
              {{ $t("newHomePage.marginCallLevel") }}
            </div>
            <div class="text-888 text-14">
              {{ $t("newHomePage.marginCallLevelTip") }}
            </div>
          </div>
          <div class="item text-left">
            <div class="mg-bt-xs flex js-ed">
              <svg-icon
                data-_icon-name="ic_new_home_icon6"
                class="text-80 text-share"
              ></svg-icon>
            </div>
            <div class="text-18 text-bold color-04 mg-bt-xs">
              {{ $t("newHomePage.customLeverage") }}
            </div>
            <div class="text-888 text-14">
              {{ $t("newHomePage.customLeverageTip") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 您的资金，您做主 -->
    <div class="flex js-ct ">
      <div class="w-1120 ">
        <div class="title mt-120">
          <div class="mg-bt-md">{{ $t("newHomePage.youDecision") }}</div>
          <div class="text-14 text-normal">
            {{ $t("newHomePage.youDecisionTip") }}
          </div>
        </div>
        <div class="flex flex-column align-center">
          <img
            src="@/assets/newhome/assets-banner.png"
            alt=""
            class="assets-banner"
          />
          <div class="assets-box flex js-sp text-left white-bg w-920 pd-40">
            <div class="assets-item">
              <div>
                <svg-icon
                  data-_icon-name="ic_new_home_fast"
                  class="text-30 mg-bt-xl"
                ></svg-icon>
              </div>
              <div>
                <div class="text-18 text-bold color-04 mg-bt-xs">
                  {{ $t("newHomePage.instantWithdrawal") }}
                </div>
                <div class="text-888 text-14">
                  {{ $t("newHomePage.instantWithdrawalTip") }}
                </div>
              </div>
            </div>
            <div class="assets-item">
              <div>
                <svg-icon
                  data-_icon-name="ic_new_home_nofee"
                  class="text-30 mg-bt-xl"
                ></svg-icon>
              </div>
              <div>
                <div class="text-18 text-bold color-04 mg-bt-xs">
                  {{ $t("newHomePage.zeroTradFee") }}
                </div>
                <div class="text-888 text-14">
                  {{ $t("newHomePage.zeroTradFeeTip") }}
                </div>
              </div>
            </div>
            <div class="assets-item">
              <div>
                <svg-icon
                  data-_icon-name="ic_new_home_localpay"
                  class="text-30 mg-bt-xl"
                ></svg-icon>
              </div>
              <div>
                <div class="text-18 text-bold color-04 mg-bt-xs">
                  {{ $t("newHomePage.localPay") }}
                </div>
                <div class="text-888 text-14">
                  {{ $t("newHomePage.localPayTip") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 稳控风险 -->
    <div class="flex js-ct ">
      <div class="w-1120 ">
        <div class="title mt-120">
          <div class="mg-bt-md">{{ $t("newHomePage.steadyRisk") }}</div>
          <div class="text-14 text-normal">
            {{ $t("newHomePage.steadyRiskTip", { appName: state.appName }) }}
          </div>
        </div>
        <div class="flex js-sp align-center">
          <div class="text-left risk-box">
            <div class="mg-bt-xl">
              <div class="text-18 text-bold color-04 mg-bt-xs">
                {{ $t("newHomePage.transparentTrading") }}
              </div>
              <div class="text-888 text-14">
                {{ $t("newHomePage.transparentTradingTip") }}
              </div>
            </div>
            <div class="mg-bt-xl">
              <div class="text-18 text-bold color-04 mg-bt-xs">
                {{ $t("newHomePage.multipleLicenses") }}
              </div>
              <div class="text-888 text-14">
                {{ $t("newHomePage.multipleLicensesTip") }}
              </div>
            </div>
            <div class="">
              <div class="text-18 text-bold color-04 mg-bt-xs">
                {{ $t("newHomePage.accountSeparation") }}
              </div>
              <div class="text-888 text-14">
                {{ $t("newHomePage.accountSeparationTip") }}
              </div>
            </div>
          </div>
          <img src="@/assets/newhome/risk.png" alt="" class="risk-img" />
        </div>
      </div>
    </div>
    <!-- 专注于交易，其他的交给我们 -->
    <div class="flex js-ct ">
      <div class="w-1120 ">
        <div class="title mt-120">
          <div class="mg-bt-md">{{ $t("newHomePage.focusTrad") }}</div>
          <div class="text-14 text-normal">
            {{ $t("newHomePage.focusTradTip") }}
          </div>
        </div>
        <div class="flex js-sp align-center">
          <img src="@/assets/newhome/trad.png" alt="" class="risk-img" />
          <div class="text-left risk-box">
            <div class="mg-bt-xl">
              <div class="text-18 text-bold color-04 mg-bt-xs">
                {{ $t("newHomePage.lowerTradCosts") }}
              </div>
              <div class="text-888 text-14">
                {{ $t("newHomePage.lowerTradCostsTip") }}
              </div>
            </div>
            <div class="mg-bt-xl">
              <div class="text-18 text-bold color-04 mg-bt-xs">
                {{ $t("newHomePage.zeroMarketImpact") }}
              </div>
              <div class="text-888 text-14">
                {{ $t("newHomePage.zeroMarketImpactTip") }}
              </div>
            </div>
            <div class="">
              <div class="text-18 text-bold color-04 mg-bt-xs">
                {{ $t("newHomePage.betterTradeExecution") }}
              </div>
              <div class="text-888 text-14">
                {{ $t("newHomePage.betterTradeExecutionTip") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 即日起选择值得信赖的经纪商交易 -->
    <div class="mg-hr-120">
      <div class="flex flex-column align-center">
        <img
          src="@/assets/newhome/foot-banner.png"
          alt=""
          class="foot-banner"
        />
        <div
          class="assets-box flex js-sp align-center text-left bg-04 w-1120 pd-60 text-white"
        >
          <div>
            <div class="text-30 text-bold mg-bt-lg">
              {{ $t("newHomePage.footTit") }}
            </div>
            <div class="text-18">
              {{ $t("newHomePage.footTip", { appName: state.appName }) }}
            </div>
          </div>
          <div
            v-if="!isLogin"
            class="over-bg radius-10 color-04 text-20 text-bold reg-btn text-center point"
            @click="toPath('/register')"
          >
            {{ $t("register.register") }}
          </div>
        </div>
      </div>
    </div>
    <!-- 客服悬浮图标 -->
    <div
      class="server-box flex align-center js-ct"
      @click="toPath"
      v-if="state.service_url === 'contact'"
    >
      <img
        src="@/assets/images/serverbox.png"
        alt=""
        srcset=""
        class="server-icon"
      />
    </div>
    <!-- 新增的第三方客服 -->
    <!-- <div
      class="server-box flex align-center js-ct"
      v-if="state.service_url === 'online'"
    >
      <a href="#" onclick="psOpenWindow(); return false;"
        ><img
          src="@/assets/images/serverbox.png"
          alt=""
          srcset=""
          class="server-icon"
      /></a>
    </div> -->
    <!-- 客服弹窗 -->
    <ServerBox
      @oncancer="state.dialog = false"
      @onconfirm="state.dialog = false"
      :dialog="state.dialog"
    />
    <!-- 新增的gd客服开始-->
    <!-- <div
      class="server-box flex align-center js-ct point"
      v-if="state.service_url === 'online'"
      @click="openIframe()"
    >
      <img
        src="@/assets/images/serverbox.png"
        alt=""
        srcset=""
        class="server-icon"
      />
    </div>
    <div v-if="state.isIframeVisible" class="iframe-overlay" @mousewheel.prevent>
      <iframe
        ref="iframe"
        :src="state.iframeSrc"
        class="iframe-content"
      >
      </iframe>
      <svg-icon
        data-_icon-name="ic_server_close"
        class="text-28 close-button"
        @click="closeIframe"
      ></svg-icon>
    </div> -->
    <!-- 新增的gd客服结束-->
    <!-- 法币交易三方 -->
    <ThirdFiatBox
      @oncancer="state.thirDialog = false"
      @onconfirm="state.thirDialog = false"
      :dialog="state.thirDialog"
    ></ThirdFiatBox>
    <div class="box">
      <el-dialog
        v-model="state.dialogVisible"
        v-if="state.dialogParam?.content_type == 1"
        :close-on-click-modal="false"
        center
        top="210px"
        width="400px"
      >
        <div class="dialog-box">
          <img src="@/assets/dialog.png" alt="" srcset="" class="dialog-img" />
          <div
            v-html="state.dialogParam?.content_text"
            class="dialog-desc"
          ></div>
          <div
            class="letter-btn share-color text-16 text-bold login-btn text-center point"
            @click="toDialogPath"
          >
            {{ $t("global.tryNow") }}
          </div>
        </div>
      </el-dialog>
      <div class="img-box">
        <el-dialog
          v-model="state.dialogVisible"
          center
          :show-close="false"
          top="210px"
          width="400px"
          v-if="state.dialogParam?.content_type == 2"
        >
          <img
            :src="state.dialogParam?.source_url"
            alt=""
            srcset=""
            class="dialog-img"
            @click="toDialogPath"
          />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, watch, computed } from "vue";
import ServerBox from "@/components/homeModel/serverBox";
import ThirdFiatBox from "@/components/homeModel/thirdFiatBox";
import { useStore } from "vuex";
import jumpChat from "@/commonapi/chat";
import { useRouter } from "vue-router";
import { getHomeWindowsStatus } from "@/api/system";
import { decimalDigitsInputFilter } from "@/utils/common";

export default {
  components: {
    ServerBox,
    ThirdFiatBox,
    // FiatBanner,
  },
  setup() {
    const $store = useStore();
    const $router = useRouter();
    const state = reactive({
      list_param: { limit: 2, page: 0 },
      timer: null,
      decimalDigitsInputFilter,
      type: 1, //模板
      defiInfo: [], //挖矿信息
      wealthInfo: [], //理财宝信息
      bannerList: [],
      countryFiatList: [],
      loading: false,
      dialog: false,
      thirDialog: false,
      dialogExchange: false,
      tableData: [], //交易对列表
      noticeList: [], //公告列表
      changeData: {}, //涨幅榜数据变动
      $websocketTool: {},
      isBuy: true,
      param: { currency_match_type: 1 },
      appName: window.VUE_APP_NAME,
      active: 1, //交易对tab
      service_url: "", //判断客服跳转类型
      defi_ore_status: false,
      wealth_management_status: false,
      currency_transaction_status: true, //币币交易
      dialogVisible: false,
      dialogParam: {},
      defi: false,
      wealth: false,
      lever: false,
      secondLever: false,
      follow_status: false,
      stock_status: false,
      stock_lever_status: false,
      stock_second_lever_status: false,
      platform_cover_image: "", //首页封面图
      platform_video_link: "", //首页视频链接
      showPlay: false, //是否展示封面播放图
      showVideo: true,
      easy_buy_type: 3,
      iframeSrc: "",
      isIframeVisible: false,
      height: 0,
    });
    const { chat } = jumpChat();
    const activeNames = ref([]);
    const handleChange = (val) => {
      console.log(val);
    };
    const systemInfo = computed(() => {
      return $store.state.user.systemInfo;
    });
    const userInfo = computed(() => {
      return $store.state.user.userInfo;
    });
    const isLogin = computed(() => {
      return $store.state.user.token ? true : false;
    });
    watch(
      () => systemInfo.value,
      () => {
        getmodel();
      }
    );
    const getmodel = () => {
      systemInfo.value?.forEach((ele) => {
        if (ele.key === "easy_buy_type") {
          state.easy_buy_type = ele.value;
        }
        if (ele.key === "service_url") {
          const show = ele.value; //online-在线客服页；contact-社交客服页
          state.service_url = show;
          state.dialog = false;
          if (state.service_url === "contact") {
            // 在线客服打开注释
            // zE(function() {
            //   zE.hide();
            // });
            //更新的接口
            // window.zE("messenger", "hide");
          } else {
            chat();
            // 在线客服
          }
        }
      });
    };
    //判断客服跳转和其他链接跳转
    const toPath = (url = "") => {
      if (!url) {
        if (state.service_url === "contact") {
          state.dialog = true;
        }
      } else {
        $router.push(url);
      }
    };
    //首页弹窗点击跳转
    const toDialogPath = () => {
      if (state.dialogParam.skip_type === 1) {
        if (state.dialogParam.skip_url) {
          window.open(state.dialogParam.skip_url);
        }
      } else {
        if (state.dialogParam.skip_url) {
          if (state.dialogParam.skip_url === "defi" && state.defi) {
            $router.push("/defiFinancial");
          }
          if (state.dialogParam.skip_url === "wealth" && state.wealth) {
            $router.push("/wealthFinancial");
          }
          if (state.dialogParam.skip_url === "lever" && state.lever) {
            $router.push("/lever");
          }
          if (
            state.dialogParam.skip_url === "secondLever" &&
            state.secondLever
          ) {
            $router.push("/secondlever");
          }
          if (
            state.dialogParam.skip_url === "fastExchange" &&
            state.fastExchange
          ) {
            $router.push("/flashAgainst");
          }
        }
      }
    };
    const getHomeDialogStatus = () => {
      getHomeWindowsStatus().then((res) => {
        if (!res.data) {
          return false;
        }
        state.dialogParam = res.data;
        //没弹过弹窗或者弹窗内容id变过或者弹窗是每次都要弹的或者激活的时间不一致
        state.dialogVisible =
          !$store.state.user.activeParam.showStatus ||
          res.data.id != $store.state.user.activeParam.id ||
          res.data.windows_times === 2 ||
          res.data.activation_time !=
            $store.state.user.activeParam.activation_time;
        let obj = {
          activation_time: res.data.activation_time, //激活时间
          id: res.data.id, //内容id
          showStatus: true, //是否弹出过
        };
        $store.commit("user/SET_ACTIVEPARAM", obj);
        systemInfo.value?.forEach((ele) => {
          if (ele.key === "defi_ore_status") {
            state.defi = ele.value;
          }
          if (ele.key === "wealth_management_status") {
            state.wealth = ele.value;
          }
          if (ele.key === "lever_status") {
            state.lever = ele.value;
          }
          if (ele.key === "second_lever_status") {
            state.secondLever = ele.value;
          }
        });
      });
    };
    const iframe = ref();
    const openIframe = () => {
      const baseUrl = process.env.VUE_APP_GD_SERVER; // 替换为你的外部链接
      let iframeParam = {};
      if ($store.state.user?.token) {
        iframeParam = {
          // uuid: userInfo.value?.user_invite_code,
          name: userInfo.value?.user_invite_code,
          eMail: userInfo.value?.user_account,
        };
      } else {
        let timeServer = localStorage.getItem("timeServer");
        if (!timeServer) {
          localStorage.setItem("timeServer", new Date().getTime());
        }
        iframeParam = {
          // uuid: timeServer || new Date().getTime(),
          name: timeServer || new Date().getTime(),
        };
      }
      const params = new URLSearchParams({
        ...iframeParam,
      }).toString();
      state.iframeSrc = `${baseUrl}&${params}`;
      state.isIframeVisible = true;
    };
    const closeIframe = () => {
      state.isIframeVisible = false;
      state.iframeSrc = ""; // 清空 src 以便关闭 iframe
    };
    onMounted(() => {
      getmodel();
      getHomeDialogStatus();
    });
    return {
      activeNames,
      handleChange,
      systemInfo,
      getmodel,
      toPath,
      chat,
      state,
      toDialogPath,
      getHomeDialogStatus,
      openIframe,
      closeIframe,
      iframe,
      isLogin,
    };
  },
};
</script>

<style lang="less" scoped>
.assets-banner {
  border-radius: 12px;
  width: 100%;
  height: 500px;
}

a:active,
a:focus {
  color: inherit;
}
.color-04 {
  color: #040404;
}
.text-42 {
  font-size: 42px;
}
.reg-login-btn {
  min-width: 76px;
  padding: 12px;
  box-sizing: border-box;
}
.mg-tp-40 {
  margin-top: 40px;
}
.mg-lf-banner {
  margin-inline-start: 13.2%;
}
.text-80 {
  font-size: 80px;
}
.iframe-overlay {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 3000;
}

.iframe-content {
  border: none;
  width: 360px;
  height: 480px;
  z-index: 2999;
  overflow-x: hidden;
  // padding: 16px 0px;
  box-sizing: border-box;
  background: #f8f8f8;
}

.close-button {
  position: absolute;
  top: 28px;
  right: 20px;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 3000;
}
.banner {
  width: 100%;
  height: 705px;
  position: relative;
  background-image: url("../assets/newhome/banner.png");
  // background-size: 100% 100%;
  background-repeat: no-repeat;
  // background-position: center;
  background-size: cover;
}
.point-box {
  width: 100%;
  height: 508px;
  position: relative;
  background-image: url("../assets/images/point_bg.png");
  // background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 50px;
  box-sizing: border-box;
}
.pd-tp-60 {
  padding-top: 60px;
}
.mb-86 {
  margin-bottom: 86px;
}
.banner-btn {
  min-width: 120px;
  border: 1px solid;
  border-image-source: linear-gradient(270deg, #ff9595 -3.82%, #e54b4b 100%);
  background: rgba(229, 75, 75, 0.6);
  padding: 4px 36px;
  border-radius: 50px;
}

.pd-tp-120 {
  padding-top: 120px;
}
.pd-bt-120 {
  padding-bottom: 120px;
}
.desc-img {
  width: 578px;
  height: 434px;
  margin-right: 55px;
}
.introduction {
  width: 467px;
}
.global-img {
  width: 444px;
  height: 374px;
  margin-inline-start: 61px;
}
.title {
  font-size: 30px;
  margin-bottom: 60px;
  font-weight: 600;
}
.mt-120 {
  margin-top: 120px;
}
.w-1120 {
  width: 1120px;
}
.item {
  background-color: #fff;
  padding: 16px;
  border-radius: 10px;
  width: 320px;
}
.text-100 {
  font-size: 100px;
}
.item-box {
  background: linear-gradient(
    270deg,
    rgba(255, 149, 149, 0.15) -3.82%,
    rgba(229, 75, 75, 0.15) 100%
  );
  background-blend-mode: screen;
  border-radius: 20px;
  padding: 32px 32px 54px 32px;
  width: 349px;
  box-sizing: border-box;
}
.item-img {
  width: 100%;
  height: 330px;
}
.center-serves {
  width: 433px;
}
.future {
  height: 400px;
  width: 100%;
  position: relative;
  background-image: url("../assets/images/future_bg.png");
  // background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.w-1000 {
  width: 1000px;
}
.text-ddd {
  color: #dddddd;
}
.text-46 {
  font-size: 46px;
}
:deep(.el-collapse-item__header),
:deep(.el-collapse-item__wrap) {
  background-color: #292929;
  color: #fff;
  border: none;
  border-radius: 8px;
}
:deep(.el-collapse-item:nth-child(even) .el-collapse-item__header),
:deep(.el-collapse-item:nth-child(even) .el-collapse-item__wrap) {
  background-color: #121212;
}
:deep(.el-collapse) {
  border: none;
}
:deep(.el-collapse-item__header) {
  margin-bottom: 2px;
  padding: 10px;
  height: max-content;
}
.lh-40 {
  line-height: 40px;
}
.lh-74 {
  line-height: 74px;
}
.exp-blend {
  mix-blend-mode: plus-lighter;
}
.bg-000 {
  background-color: #000;
}
.w-1440 {
  width: 1440px;
  min-width: 1120px;
}
.min-250 {
  min-width: 250px;
  width: min-content;
  height: 22%;
}
.min-500 {
  min-width: 500px;
  width: min-content;
}
.mg-rt-76 {
  margin-inline-end: 76px;
}
.mg-lf-76 {
  margin-inline-start: 76px;
}
:deep(.el-collapse-item__arrow) {
  margin-inline-end: 8px;
  margin-inline-start: auto;
}
.text-justify {
  text-align: justify;
}
.dialog-box {
  padding: 15px 15px 0px;
  .dialog-img {
    width: 100%;
    height: 154px;
    display: block;
    margin-bottom: 24px;
  }
  .dialog-desc {
    margin-bottom: 24px;
  }
  .letter-btn {
    width: 100%;
    height: 46px;
    line-height: 46px;
    border-radius: 4px;
  }
}
.server-box {
  position: fixed;
  right: 60px;
  bottom: 60px;
  background: rgba(55, 113, 226, 0.5);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  filter: drop-shadow(0px 8px 14px rgba(55, 113, 226, 0.3));
  z-index: 1999;
}
.server-icon {
  width: 67.5px;
  height: 67.5px;
}
.img-box /deep/.el-dialog--center .el-dialog__body {
  padding: 0;
  .dialog-img {
    margin-bottom: 0px;
    width: 100%;
    height: auto;
    display: block;
  }
}
.box /deep/.el-dialog__header {
  border-bottom: none;
  padding: 0;
}
.assets-box {
  border-radius: 20px;

  box-sizing: border-box;
  margin-top: -108px;
  position: relative;
}
.pd-40 {
  padding: 40px;
}
.pd-60 {
  padding: 60px;
}
.w-920 {
  width: 920px;
}
.assets-item {
  width: 240px;
}
.risk-img {
  height: 600px;
  width: 600px;
  border-radius: 20px;
}
.risk-box {
  width: 460px;
}
.bg-04 {
  background-color: #040404;
}
.foot-banner {
  width: 100%;
  height: 288px;
}
.mg-hr-120 {
  margin: 120px 0;
}
.reg-btn {
  min-width: 104px;
  padding: 8px;
  box-sizing: content-box;
}
.w-50 {
  width: 50%;
}
</style>
